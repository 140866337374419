<template>
    <!-- loading -->
    <div v-show="isLoading" class="page-loading">
        <Spin size="large"></Spin>
    </div>
</template>

<script>
export default {
    name: 'PageLoading',
    computed: {
        isLoading() {
            return this.$store.state.isGlobalSpin;
        }
    },
};
</script>

<style lang="less">
.fontad {
    font-size: 50px;
}

.page-loading {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 99999;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-loading img {
    width: 50px;
    height: 50px;
}
</style>
