import axios from 'axios'
import configData from '@/config'

export  function  isNewVersion (){
    // console.log('当前版本',configData.version)
    // let oldVersion = configData.version;
    // let localSeagullCloudVersion = localStorage.getItem('contractVersion');
    // if (localSeagullCloudVersion && localSeagullCloudVersion != oldVersion) {
    //     localStorage.setItem('contractVersion', oldVersion);              
    //     window.location.reload();
    //     return;
    // } else {
    //     localStorage.setItem('contractVersion', oldVersion);
    // }
    // return
    let href = window.location.href;   
    if (href.startsWith('http://192.168.1') || href.startsWith('https://192.168.1')) {        
        return
    }
    let url = configData.apiUrl + `/version.json?t=${new Date().getTime()}`
    axios.get(url).then(res => {
        if (res.status === 200) {
            console.log('当前版本为：', res.data.admin)
            let oldVersion = res.data.admin;
            let localSeagullCloudVersion = localStorage.getItem('contractVersion');
            if (localSeagullCloudVersion && localSeagullCloudVersion != oldVersion) {
                localStorage.setItem('contractVersion', oldVersion);
				// window.location.reload();//不清除缓存刷新页面
                window.location.reload(true);//强制清除缓存刷新页面
                return;
            } else {
                localStorage.setItem('contractVersion', oldVersion);
            }
        }
    });

}

