<template>
    <div class="layout">
        <Layout :style="'min-height:'+ innerHeight + 'px'">
            <Sider ref="side1" hide-trigger collapsible :collapsed-width="78" v-model="isCollapsed">
                <div class="logo-side">
                    <div class="logo-item">
                        <img v-if="!information.site_logo" src="../../assets/imgs/logo.png"/>
                        <img v-else :src="information.site_logo"/>
                    </div>
                    <div class="company-name" v-if="!isCollapsed">  
                        <div v-if="!information.site_title"><span>速速</span>云签</div>
                        <div v-else>{{information.site_title}}</div>
                    </div>
                </div>
                <Menu :active-name="String($route.name)"
                    ref="menu"
                    theme="light"
                    width="auto"
                    :open-names="openNames">
                    <div>
                        <div v-for="(item,index) in muen" :key="index">
                            <div v-if="item.children.length>0 && item.children[0].is_submenu === 1">
                                <Submenu :name="item.permissions_rote" >
                                <template slot="title" style="display: flex;align-items: center">
                                    <div class="l-icon"><img :src="item.icon"/></div>
                                    <div v-if="!isCollapsed">{{ item.permissions_name }}</div>
                                </template>
                                <MenuItem  v-for="(child,idx) in item.children" :key="idx" :name="child.permissions_rote" :to="child.path">
                                    <div>{{ child.permissions_name }}</div>
                                </MenuItem>                  
                                </Submenu>
                            </div>
                            <div v-else>
                                <MenuItem :name="item.permissions_rote" :to="item.path" style="display: flex;align-items: center">
                                    <div class="l-icon"><img :src="item.icon"/></div>
                                    <div v-if="!isCollapsed">{{ item.permissions_name }}</div>
                                </MenuItem>
                                <!-- <MenuItem v-if="item.id==8"  name="application-center" to="/setting/center">
                                <div>应用中心</div>
                                </MenuItem> -->
                            </div>
                        </div>
                    </div>
                </Menu>
            </Sider>
            <Layout>
                <Header :style="{padding: 0}" class="layout-header-bar">
                    <div @click="collapsedSider"
                        style="margin-top: 20px;margin-left: 10px"
                        class="l-icon else-icon">
                        <img src="../../assets/imgs/5.png"/>
                    </div>
                    <div class="r-fixed">
                        <div class="all-icon">
                            <Tooltip content="订单记录" placement="bottom">
                                <img src="../../assets/setimg/cheche.png" @click="showOrderList" />
                            </Tooltip>
                            <Tooltip content="返回首页" placement="bottom">
                                <img src="../../assets/imgs/23.png" @click="goHome" />
                            </Tooltip>                            
                            <!-- <Tooltip content="老版本切换" placement="bottom" v-if="version === '1' && $store.state.identity != 3 &&userInfo.uniacid_id != 311">
                                <img src="../../assets/imgs/28.png" @click="changeEdition" />
                            </Tooltip> -->
                            <Tooltip content="消息通知" placement="bottom">
                                <img src="../../assets/imgs/25.png" @click="showNotice"/>
                            </Tooltip>
                            <div class="red flex-c" v-if="unread_num > 0">{{unread_num}}</div>
                        </div>
                        <div class="user-del">
                            <div class="user-img flex-c">{{ userInfo.real_name?userInfo.real_name.substring(0,1):'' }}</div>
                            <div class="user-name">{{ userInfo.real_name }}</div>
                            <div class="sign-out">
                                <div class="out flex-c" @click=" goUpdata ">修改密码</div>
                                <div class="out flex-c" @click="logout">退出登录</div>
                            </div>
                        </div>
                    </div>
                </Header>
                <Content :style="{minHeight: '260px'}">
                    <div style="height: 90vh;overflow: auto;padding: 20px">
                        <router-view v-if="reload"></router-view>
                    </div>
                </Content>
                <div style="text-align: center;margin-bottom:5px">{{information.floor_cr}}</div>
            </Layout>
            <div class="notice-pop" v-if="tryTrue">
                <div class="pop"></div>
                <div class="notice-table fixed">
                    <div class="modal-title">消息通知</div>
                    <div class="close-it" @click="tryTrue = false"><Icon type="md-close" size="25" /></div>
                    <div style="padding: 8px 20px">
                        <Table :columns="columns"  @on-row-click="clickNotice" :row-class-name="rowClassName" :data="data"></Table>
                        <Page
                        :total="tables.total"
                        :current="tables.current"
                        @on-change="changeTables"
                        :page-size="tables.limit"
                        style="text-align: right;margin-top: 16px"
                        show-elevator show-total />
                    </div>                    
                </div>
            </div>
            <div class="notice-pop" v-if="turnList">
                <div class="pop"></div>
                <div class="order-table fixed">
                    <div class="modal-title">订单记录</div>
                    <div class="close-it" @click="turnList = false"><Icon type="md-close" size="25" /></div>
                    <div style="padding: 8px 20px">
                        <Table :columns="orderColumns"   :row-class-name="rowClassName" :data="orderListArr" :loading='loadingTabA'>
                            <template  slot-scope="{ row, }" slot="is_pay" > 
                                <div >{{row.is_refund == 1 ?'已退款':(row.is_pay == 0 ? '未付款': '付款成功')}}</div>                
                            </template>
                            <template  slot-scope="{ row, }" slot="application_name" > 
                                <!-- 独立域名 -->
                                <!-- {{row.domain_name == '' ? '': '—'+row.domain_name}} -->
                                <div >{{row.application_name}}{{row.number == 0 ? '': '—'+row.number+'条'}}</div>                
                            </template>            
                        </Table>
                        <Page
                        :total="tablea.total"
                        :current="tablea.current"
                        @on-change="changeTablea"
                        :page-size="tablea.limit"
                        style="text-align: right;margin-top: 16px"
                        show-elevator show-total />
                    </div>
                </div>
            </div>
            <div v-if='isPolling'><loadings v-if="isGlobalSpin"></loadings></div>
            <!-- 公告详情-->
            <Modal v-model="noticeForm.contentModel" :mask-closable="false" footer-hide  :title="noticeForm.contentTitle">
                <div style="padding-top:10px;min-height:200px">{{noticeForm.contentNotice}}</div>
            </Modal>
            <!-- 修改密码-->
            <modal v-if="showModal" :modalInfo="modalInfo" @giveVal="getVal" @closeModal="showModal=false"></modal>
        </Layout>
        
        <!-- 互联中台：1    互联溯源：2   速速云签：3   人如云：4   海鸥云：5   其余：客服中心-->
        <ContactButton :type="3" /> 
        <Modal
            title="提示"
            v-model="noticeData.visable" 
            :closable="false"
            :mask-closable="false">
            <p>{{ noticeData.name }}</p> 
            <div slot="footer">
                <Button  type="primary" @click="noticeData.visable = false ">确认</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import {
    AccountLogout,
    announceList,
    changePassword,
    readAnnounce,
    switchEdition
} from '../../api/interFace'
import { get_orderlist,get_notice } from '@/api/center'
import loadings from '@/components/loadings/loadings'
import {getCookies,removeCookies} from '@/utils/helper'
import modal from '../../components/modal/index'
import vm from '@/utils/util.js'

import ContactButton from '@/components/ContactButton/ContactButton.vue';
// import config from '@/config/index'
// var _this;
// var timer 
export default { 
    data () {
        return {
            showload: true,
            isCollapsed: false,
            showModal: false,
            continf: false,
            innerHeight: 0,
            openNames: [],
            reload: true,
            orderColumns:[
                {
                    title: '应用名',
                    key: 'title',
                    align: "center",
                    slot:"application_name",
                    width:'300',
                },
                {
                    title: '订单编号',
                    align: "center",
                    key: 'order_no',
                    width:'200',          
                },        
                {
                    title: '支付类型',
                    align: "center",
                    key: 'is_pay',
                    slot:"is_pay"
                },
                {
                    title: '金额',
                    align: "center",
                    key: 'pay_money',
                },        
                {
                    title: '订单时间',
                    align: "center",
                    width:'200',
                    key: 'created_at'
                },
                {
                    title: '支付时间',
                    align: "center",
                    width:'200',
                    key: 'updated_at'
                },
            ],
            columns: [
                {
                    title: '标题',
                    key: 'title'
                },
                {
                    title: '类型',
                    key: 'notice_type',
                    render: (h, params) => {
                        let auth_text = "普通公告",
                            auth_color= "#515a6e";
                        if (params.row.notice_type == 1) {
                            auth_text = "紧急公告",
                            auth_color= "#FF0000";
                        } else if (params.row.notice_type == 2) {
                            auth_text = "重要公告",
                            auth_color= "#FF0000";
                        }
                        return h('div', [
                            h('span', {
                                style: {color: auth_color},
                                props: {
                                type: 'text',
                                size: 'small'
                                }
                            }, auth_text)
                        ]);
                    }
                },
                {
                    title: '时间',
                    key: 'send_time'
                }
            ],
            data: [],
            tables:{
                page:1,
                total:0,
                limit:5,
            },
            tablea:{
                page:1,
                total:0,
                limit:5,
            },
            tryTrue:false,
            unread_num:'',
            noticeForm:{
                contentModel:false,
                contentTitle:"",
                contentNotice:""
            },
            information:{
                floor_cr:'Copyright 2020 安徽互联造物云计算有限公司'
            },
            modalInfo: {
                title: '修改密码',
                inputList: [
                    {
                        label: '新密码',
                        inputType: 'password',
                        inputVal: ''
                    },
                    {
                        label: '确认新密码',
                        inputType: 'password',
                        inputVal: ''
                    },                    
                ]
            },
            seal:{
                password:'',
                new_password:'',
                true_password:'',
                real_name: '',
            },
            noticeData:{
                name: '',
                visable: false
            },
            version:'',
            turnList: false,
            orderListArr: [],
            loadingTabA:false,
            path:"",
            socket:"",
            timer:"",
        }
    },
    components:{ modal,loadings,ContactButton },
    metaInfo: {
		title:'首页',
		meta: [
			{ name: 'viewport', content: 'width=device-width,user-scalable=yes,initial-scale=0.3,minimum-scale=0.3,maximum-scale=2' }
		]
	},
    mounted() {
        // this.init()
        this.innerHeight = window.innerHeight;
        this.selectMenu();
        let arr = []
        let arr2 = [] //唐 应用中心列表
        this.muen.forEach(item=>{
            if(item.permissions_rote === 'settingIndex'){
                arr=item.children
            }
            //唐 应用中心列表
            if(item.permissions_rote === 'application-center'){
                arr2 = item.children
            }
        })
        this.$store.commit('setSetting', arr)
        this.$store.commit('setCenter', arr2) //唐 应用中心列表
    },
    watch: {
        '$route.path'() {
            this.selectMenu();
        }
    },
    computed: {
        muen(){
            return this.$store.state.menuSecond      
        },
        userInfo(){
            return this.$store.state.userInfo
        },
        isGlobalSpin(){
            return this.$store.state.isGlobalSpin
        },
        isPolling(){
            return this.$store.state.isPolling
        }
    },
    created() {
        this.changeCompanyInfo();
        this.version = getCookies('version')
        this.getAnnounceList()
        console.log('userInfo',this.$store.state.userInfo)
        this.getIsSureDelCont()
        // let uid = this.$store.state.userInfo.id
        // this.path = `${config.path}?uid=${uid}`
        // this.path = `wss://cscontract.hulianzaowu.top:9501?uid=${uid}`
    },
    destroyed () {
        // 销毁监听
        this.timer = ""
        // clearInterval(this.timer)
        // this.socket.onclose = this.close
    },
    methods: {
        // 是否确认删除一年前的合同
        getIsSureDelCont(){ 
            get_notice().then(res=>{ 
                if(res.data.status == 1){ 
                    this.noticeData.name = res.data.name
                    this.noticeData.visable = true 
                }
            }).catch(res=>{
                console.log(res)
            })

        },
        goUpdata(){
            if(this.userInfo.role == 3){
                this.modalInfo.inputList = []
                this.modalInfo.inputList.push({label: '新密码', inputType: 'password',inputVal: ''})
                this.modalInfo.inputList.push({label: '确认新密码', inputType: 'password',inputVal: ''})
                this.modalInfo.inputList.push({label: '昵称', inputType: 'text',inputVal: this.userInfo.real_name})
                this.showModal = true
            }else {
                this.showModal = true
            }   
        },
        changeCompanyInfo(){
            let companyInfo = JSON.parse(localStorage.getItem(document.domain+'_companyInfo'))
            if (companyInfo != undefined) {
                this.information = companyInfo
                window.document.title = this.information.site_title?this.information.site_title:"【速速云签】电子合同、电子签名、电子签章、印章管理、数据存证paap系统服务平台"
                if (this.information.site_logo != "") {
                    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                    link.type = 'image/x-icon';
                    link.rel = 'shortcut icon';
                    link.href = this.information.site_logo
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
            }else {
                window.document.title = "【速速云签】电子合同、电子签名、电子签章、印章管理、数据存证paap系统服务平台"
            }
        },
        getAnnounceList(){
            announceList(this.tables).then(res=>{
                this.data = res.data.data || []
                this.tables.total = res.data.total;
                this.unread_num = res.data.unread_num;
            })
        },
        getOrderList(){
            this.loadingTabA = true
            get_orderlist(this.tablea).then(res=>{
                this.loadingTabA = false
                this.orderListArr = res.data.data || []
                this.tablea.total = res.data.total;
                // this.unread_num = res.data.unread_num;
            })
        },
        getVal(e) {
            this.seal.new_password = e[0]
            this.seal.true_password = e[1]
            if(this.userInfo.role == 3){
                this.seal.real_name = e[2]
            }
            changePassword(this.seal).then(async res => {
                this.$Message.success(res.msg)
                this.showModal = false
                setTimeout(()=>{
                    let initpath = localStorage.getItem("InitializeUrl")
                    if( initpath ==  "" || initpath == null){
                        this.$router.replace('/')
                    }else {
                        window.location.href = initpath
                    }
                    localStorage.clear()
                    localStorage.setItem("InitializeUrl",initpath)
                },500)
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        changeEdition(){            
            switchEdition({type:0}).then(res=>{
                // window.open(res.data.url)      
                // window.location.href=' http://192.168.1.100:8081/#/jump'
                window.location.href=res.data.url
            }).catch(res=>{
                console.log(res)
            })
        },
        showNotice(){
            this.tryTrue = true
        },
        showOrderList(){
            this.$store.commit('changePollingSpin', false)
            this.getOrderList()            
            this.turnList = true
        },
        clickNotice(v){
            let that = this;
            this.noticeForm.contentModel = true;
            this.noticeForm.contentTitle = v.title;
            this.noticeForm.contentNotice = v.content;
            this.data.forEach(function(item){
                if(item.id == v.id && item.status == 0) {
                item.status = 1;
                that.tables.num--;
                }
            })
            readAnnounce({id: v.id}).then(res=>{
                this.getAnnounceList();
                console.log(res)
            }).catch({})
        },
        changeTables(v){
            this.tables.page = v;
            this.getAnnounceList();
        },
        changeTablea(v){
            this.tablea.page = v;
            this.getOrderList();
        },
        collapsedSider () {
            this.$refs.side1.toggleCollapse();
        },
        goHome(){
            this.$router.push({ path: '/index' })
        },
        handleReload () {
            this.reload = false
            setTimeout(()=>{
                this.reload = true
            },200)
        },
        //scocket
        init: function () {
            if(typeof(WebSocket) === "undefined"){
                alert("您的浏览器不支持socket")
            }else{
                // this.path = "wss://canyin.hulianzaowu.top:6001"
                // 实例化socket
                this.socket = new WebSocket(this.path)
                // 监听socket连接
                this.socket.onopen = this.open
                // 监听socket错误信息
                this.socket.onerror = this.error
                // 监听socket消息
                this.socket.onmessage = this.getMessage
                // socket关闭
                this.socket.onclose = this.close
            }
        },
        open: function () {
            console.log("socket连接成功")
            clearInterval(this.timer); //清除延迟执行 
        },
        error: function () {
            console.log("连接错误")
            // clearInterval(this.timer); //清除延迟执行
            // this.timer = setInterval(() => {
            //  this.init()
            // },5000)
        },
        getMessage: function (msg) {
            if(msg.data!=undefined){
                // let a  = JSON.stringify(JSON.parse(msg.data))
                let a  = msg.data
                if(a){
                    if(a!='打开swoole服务成功！'){
                        let s = JSON.parse(a)
                        vm.$emit('demo', s);
                    }
                }      
            }
            // localStorage.setItem("payOver", JSON.stringify(JSON.parse(msg.data)))
        },
        send: function () {
            this.socket.send('HeartBeat')
        },
        close: function () {
            console.log("socket已经关闭")
            this.timer =  setInterval(() => {
                this.init()
            },1000)
        },
        rowClassName(row){
            if(row.status == 0) {
                return  "demo-table-error-row  table-cursor-row";
            }
            return "table-cursor-row"
        },
        logout () {
            let that = this
            this.$Modal.confirm({
                title: '退出登录确认',
                content: '您确定退出当前账户吗？',
                onOk () {
                    AccountLogout({
                        id: getCookies('uuid')
                    }).then(res => {
                        console.log(res)
                        let initpath = localStorage.getItem("InitializeUrl")
                        // that.$store.commit('userInfo/type', null)
                        that.$Message.success('您已成功退出')
                        if( initpath ==  "" || initpath == null){
                            // if (that.path) {
                            //   console.log('that.path',that.path)
                            //   window.location.href = that.path
                            // } else {
                            console.log('that.$router.',that.$router)
                            that.$router.replace('/')
                            // }
                        }else {
                            window.location.href = initpath
                        }
                        localStorage.clear()
                        localStorage.setItem("InitializeUrl",initpath)
                        removeCookies('token')
                        removeCookies('expires_time')
                        removeCookies('uuid')
                    }).catch(res => {
                        console.log(res)
                    })
                }
            })
        },
        /**修改导航展开样式 */
        selectMenu() {
            this.openNames = [this.$route.name.split('-')[0]];
            this.$store.commit('setType', '')
            this.$nextTick(() => {
                this.$refs.menu.updateOpened();
            })
        },
    }
}
</script>
<style scoped>
.layout{
    position: relative;
    overflow: hidden;
}
.ivu-layout {
    background: #EDF0F9;
}
.layout-header-bar{
    background: #fff;
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
    position: relative;
}
.layout-logo-left{
    width: 90%;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    margin: 15px auto;
}
.menu-icon{
    transition: all .3s;
}
.rotate-icon{
    transform: rotate(-90deg);
}
.menu-item span{
    display: inline-block;
    overflow: hidden;
    width: 69px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width .2s ease .2s;
}
.menu-item i{
    transform: translateX(0px);
    transition: font-size .2s ease, transform .2s ease;
    vertical-align: middle;
    font-size: 16px;
}
.collapsed-menu span{
    width: 0px;
    transition: width .2s ease;
}
.collapsed-menu i{
    transform: translateX(5px);
    transition: font-size .2s ease .2s, transform .2s ease .2s;
    vertical-align: middle;
    font-size: 22px;
}
.logo-item {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    margin-left:12px
}
.logo-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.logo-side {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    box-sizing: border-box;
}
.company-name {
    font-size: 22px;   
    font-weight: bold;
    color: #545F78;
    /* 文字过长省略 */
    width: 120px;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2; 
}
.company-name span {
    color: #4877E8;
}
.r-fixed {
    position: absolute;
    right: 60px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}
.all-icon {
    display: flex;
    align-items: center;
    position: relative;
}
.red {
    position: absolute;
    top: -15px;
    right: 5px;
    width: 20px;
    height: 20px;
    background: #FD5454;
    color: white;
    font-size: 12px;
    border-radius: 50%;
}
.all-icon img {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    display: inline-block;
    cursor: pointer;
}
.user-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #1a9aef;
    margin-right: 10px;
    margin-left: 20px;
    color: white;
    font-size: 16px;
}
.user-img img {
    width: 100%;
    height: 100%;
}
.user-del {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    height: 64px;
}
.user-del:hover .sign-out {
    display: block;
}
.sign-out {
    width: 120px;
    height: 70px;
    padding-top: 5px;
    background: #fff;
    position: absolute;
    left: 15px;
    bottom: -70px;
    z-index: 999999999999999999999999;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    display: none;
}
.sign-out .out {
    height: 30px;
    text-align: center;
    display: block;
    line-height: 30px;
}

.sign-out .out:hover {
    color: #fff;
    background: #DBDEE6;
}
.user-name {
    font-size: 15px;
    color: #545F78;
}
.notice-table {
    width: 600px;
    height: 400px;
    background: white;
    border-radius: 8px;
    box-sizing: border-box;
}
.modal-title {
    font-size: 16px;
    color: #17233d;
    border-bottom:1px solid #e8eaec;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left:16px ;
}
.order-table {
    width: 1200px;
    height: 400px;
    background: white;
    border-radius: 8px;
    box-sizing: border-box;
}
.close-it {
    position: absolute;
    top: 8px;
    right: 12px;
    cursor: pointer;
}
</style>
<style>
.ivu-layout-sider {
    background: white!important;
}
.l-icon {
    width: 22px;
    height: 22px;
    margin-right: 6px;
    cursor: pointer;
}
.l-icon img {
    width: 100%;
    height: 100%;
    display: inline-block;
}
.ivu-menu-submenu-title {
    display: flex;
}
.layout-header-bar {
    border-left: 2px solid #EDF0F9;
}
.ivu-layout-header {
    line-height: unset!important;
    z-index: 999;
}
.ivu-table .demo-table-error-row td{
    color: #2c8cf0;
}
.ivu-table .table-cursor-row td{
    cursor: pointer;
}
</style>
