/**系统设置 */
export default [
    {
        path:'/setting/index',
        name:'settingIndex',
        meta: { title: '系统设置' },
        component: ()=>import('@/pages/setting/index.vue')
    }
]


