
export default [
    {
        path:'/application/center',
        name:'applicationCenter',
        meta: { title: '应用中心' },
        component: ()=>import('@/pages/application/center.vue')
    }
]

