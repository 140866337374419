import request from '@/utils/request'

/*
 * 登录
 * */
export function AccountLogin (data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}


export function sealList (data) {
    return request({
        url: 'apply/seal/seal_list',
        method: 'get',
        params: data
    })
}

export function sealCreate (data) {
    return request({
        url: 'apply/seal/create',
        method: 'POST',
        data
    })
}

export function applyList (data) {
    return request({
        url: 'apply/seal/apply_list',
        method: 'get',
        params: data
    })
}


export function examineList (data) {
    return request({
        url: 'apply/seal/examine_list',
        method: 'get',
        params: data
    })
}


export function examineSeal (data) {
    return request({
        url: 'apply/seal/examine_seal',
        method: 'POST',
        data
    })
}

export function addImgsseal (data) {
    return request({
        url: '/stamp/company/add_imgsseal',
        method: 'post',
        data
    });
}

export function seallist (data) {
    return request({
        url: '/stamp/company/sign_list',
        method: 'post',
        data
    });
}

export function editstamp (data) {
    return request({
        url: '/stamp/company/set_status',
        method: 'post',
        data
    });
}

export function deleteStamp (data) {
    return request({
        url: '/stamp/company/delete',
        method: 'post',
        data
    });
}

export function stampaddsign (data) {
    return request({
        url: '/stamp/company/add_sign',
        method: 'post',
        data
    });
}

export function addadministrator (data) {
    return request({
        url: '/user/manager/add',
        method: 'post',
        data
    });
}

export function permlist (data) {
    return request({
        url: 'user/manager/list',
        method: 'get',
        params:data
    });
}

export function addTreaty (data) {
    return request({
        url: '/user/add_treaty',
        method: 'post',
        data
    });
}

export function getTreaty (data) {
    return request({
        url: '/user/get_treaty',
        method: 'get',
        params: data
    })
}

export function createInfo (data) {
    return request({
        url: '/signatory/create_info',
        method: 'post',
        data
    });
}

export function signatoryList (data) {
    return request({
        url: '/signatory/lists',
        method: 'get',
        params: data
    })
}

export function delInfo (data) {
    return request({
        url: '/signatory/del_info',
        method: 'post',
        data
    });
}

export function updateInfo (data) {
    return request({
        url: '/signatory/update_info',
        method: 'post',
        data
    });
}


export function getInfo (data) {
    return request({
        url: '/signatory/get_info',
        method: 'get',
        params: data
    });
}

export function save (data) {
    return request({
        url: '/setting/applets/save',
        method: 'post',
        data
    });
}

export function updateRecord (data) {
    return request({
        url: '/applets/update_record',
        method: 'post',
        data
    });
}
export function savepay (data) {
    return request({
        url: '/setting/pay/save',
        method: 'post',
        data
    });
}

export function h5pay () {
    return request({
        url: '/setting/pay/h5',
        method: 'get'
    });
}

export function saveh5 (data) {
    return request({
        url: '/setting/pay/saveh5',
        method: 'post',
        data
    });
}

export function saveoem (data) {
    return request({
        url: '/setting/conf/oemSubmit',
        method: 'post',
        data
    });
}

export function getoem () {
    return request({
        url: '/setting/conf/oemIndex',
        method: 'post'
    });
}

export function getcloud () {
    return request({
        url: '/setting/conf/storageIndex',
        method: 'post'
    });
}

export function savecloud (data) {
    return request({
        url: '/setting/conf/storageSubmit',
        method: 'post',
        data
    });
}

export function applets () {
    return request({
        url: '/setting/applets/index',
        method: 'get',
    });
}

export function paylist () {
    return request({
        url: '/setting/pay/index',
        method: 'get'
    });
}

export function contractList (data) {
    return request({
        url: '/contract/index',
        method: 'post',
        data
    });
}

export function contractDetails (data) {
    return request({
        url: '/contract/details',
        method: 'post',
        data
    });
}

export function getpdf (data) {
    return request({
        url: '/applets/getpdf',
        method: 'post',
        data
    });
}

export function reminder (data) {
    return request({
        url: '/contract/reminder',
        method: 'post',
        data
    });
}

export function renewal (data) {
    return request({
        url: '/contract/edit_renewal',
        method: 'post',
        data
    });
}
export function batchList (data) {
    return request({
        url: 'contract/batch_list',
        method: 'get',
        params: data
    })
}


export function batchDetails (data) {
    return request({
        url: 'contract/batch_details',
        method: 'get',
        params: data
    })
}
export function delBatch (data) {
    return request({
        url: 'contract/del_batch',
        method: 'POST',
        data
    })
}

export function statistics (data) {
    return request({
        url: 'common/statistics',
        method: 'get',
        params: data
    })
}

export function edittempupdate (data) {
    return request({
        url: 'temp/update',
        method: 'post',
        data
    });
}

export function addtemplate (data) {
    return request({
        url: '/temp/add',
        method: 'post',
        data
    });
}

export function templist (data) {
    return request({
        url: '/temp/index',
        method: 'post',
        params:data
    });
}

export function typetemplate (data) {
    return request({
        url: '/temp/set_status',
        method: 'post',
        data
    });
}

export function deletetmp (data) {
    return request({
        url: 'temp/deletetmp',
        method: 'post',
        data
    });
}

export function deleteTable (data) {
    return request({
        url: 'temp/delete',
        method: 'post',
        data
    });
}

export function edittemplate (data) {
    return request({
        url: '/temp/edit',
        method: 'post',
        data
    });
}


export function createBatch (data) {
    return request({
        url: '/contract/create_batch',
        method: 'post',
        data
    });
}

export function delEnclosure (data) {
    return request({
        url: '/contract/del_enclosure',
        method: 'post',
        data
    });
}

export function contractadd (data) {
    return request({
        url: '/templates/create',
        method: 'post',
        data
    });
}

export function labelList (data) {
    return request({
        url: 'contract/label_lists',
        method: 'get',
        params: data
    })
} 
export function searchList (data) {
    return request({
        url: 'contract/search_lists',
        method: 'get',
        params: data
    })
} 
export function getCsList (data) {
    return request({
        url: 'system/conf/get_csloglist',
        method: 'get',
        params: data
    })
} 
export function addLabel (data) {
    return request({
        url: '/contract/add_label',
        method: 'post',
        data
    });
} 
export function editLabel (data) {
    return request({
        url: '/contract/edit_label',
        method: 'post',
        data
    });
} 
export function AccountLogout (data) {
    return request({
        url: '/logout',
        method: 'post',
        data
    })
} 
export function delLable (data) {
    return request({
        url: '/contract/del_lable',
        method: 'post',
        data
    })
} 
export function info (data) {
    return request({
        url: '/temp/info',
        method: 'post',
        data
    });
} 
export function getConfInfo (data) {
    return request({
        url: 'system/conf/get_info',
        method: 'get',
        params:data
    });
} 
export function setCsnum (data) {
    return request({
        url: '/system/conf/set_csnum',
        method: 'post',
        data
    });
} 
export function managerDelete(data) {
    return request({
        url: '/user/manager/delete',
        method: 'post',
        data
    });
} 
export function staySign (data) {
    return request({
        url: 'contract/stay_sign',
        method: 'get',
        params:data
    });
} 
export function enterpriseAuth (data) {
    return request({
        url: '/luming/enterprise/enterpriseAuth',
        method: 'post',
        data
    });
} 
export function authStatus (data) {
    return request({
        url: '/luming/enterprise/authStatus',
        method: 'post',
        data
    });
} 
export function applyRelation (data) {
    return request({
        url: `luming/enterprise/apply_relation`,
        method: 'POST',
        data
    })
} 
export function getPrivilege (data) {
    return request({
        url: 'luming/enterprise/getPrivilege',
        method: 'POST',
        data
    })
} 
export function relationStatus (data) {
    return request({
        url: 'luming/enterprise/relation_status',
        method: 'get',
        params: data
    })
}
//获取无小程序绑定码
export function get_applet_qrurl (data) {
    return request({
        url: 'appletcode/get_applet_qrurl',
        method: 'get',
        params: data
    })
} 
export function getUserList (data) {
    return request({
        url: 'user/get_user_list',
        method: 'get',
        params: data
    })
}

export function getContractReminder (data) {
    return request({
        url: 'contract/get_contract_reminder',
        method: 'get',
        params: data
    })
} 
export function announceList (data) {
    return request({
        url: '/luming/announce/announceList',
        method: 'post',
        data
    })
}

export function editContractReminder (data) {
    return request({
        url: '/contract/edit_contract_reminder',
        method: 'post',
        data
    })
}

export function relieve (data) {
    return request({
        url: '/user/manager/relieve',
        method: 'post',
        data
    })
}

export function uppassword (data) {
    return request({
        url: '/user/manager/up_password',
        method: 'post',
        data
    });
}

export function readAnnounce (data) {
    return request({
        url: '/luming/announce/readAnnounce',
        method: 'post',
        data
    })
}

//omekaiguan
export function chooseOEM (data) {
    return request({
        url: 'setting/conf/oemSwitch',
        method: 'post',
        data
    })
}
//omekaiguan
export function template_switch (data) {
    return request({
        url: 'system/conf/template_switch',
        method: 'post',
        data
    })
}

export function loginJump (data) {
    return request({
        url: `/luming/login/jump`,
        method: 'POST',
        data
    })
}

export function getLoginInfo (data) {
    return request({
        url: 'luming/login/home',
        method: 'post',
        data
    })
}

export function switchEdition (data) {
    return request({
        url: '/user/admin/switch_edition',
        method: 'post',
        data
    })
}


export function updateLabel (data) {
    return request({
        url: '/contract/update_label',
        method: 'post',
        data
    })
}

export function changePassword (data) {
    return request({
        url: '/user/up_pass',
        method: 'post',
        data
    });
}

export function contractTransfer (data) {
    return request({
        url: '/user/contract_transfer',
        method: 'post',
        data
    });
}

export function saveQrcode (data) {
    return request({
        url: '/applets/save_qrcode',
        method: 'post',
        data
    });
}

export function voidContract (data) {
    return request({
        url: '/contract/void',
        method: 'post',
        data
    });
}
export function deleteContract (data) {
    return request({
        url: '/contract/del_contract',
        method: 'post',
        data
    });
}
export function modifyContractTime (data) {
    return request({
        url: '/contract/modify_contract_time',
        method: 'post',
        data
    });
}
// 云存储开关
export function storageSwitch (data) {
    return request({
        url: '/setting/conf/storageSwitch',
        method: 'post',
        data
    });
}
//合同管理搜索副管理列表
export function get_managelist (data) {
    return request({
        url: 'contract/get_managelist',
        method: 'get',
        params: data
    })
}
//合同操作日志
export function get_contractloglist (data) {
    return request({
        url: 'contract/get_contractloglist',
        method: 'get',
        params: data
    })
}
//获取模板名称
export function get_templatename (data) {
    return request({
        url: 'signatory/get_templatename',
        method: 'get',
        params: data
    })
}
//待签署合同
export function stay_signcontract (data) {
    return request({
        url: 'apply/seal/stay_signcontract',
        method: 'get',
        params: data
    })
}
//删除公章申请
export function del_applyrecord (data) {
    return request({
        url: 'apply/seal/del_applyrecord',
        method: 'post',
        data
    })
}
// 通过域名获取oem
export function get_oem (data) {
    return request({
        url: 'login/get_oem',
        method: 'get',
        params: data
    })
}
// 查询公章开关状态
export function get_sealstatus (data) {
    return request({
        url: 'apply/seal/get_sealstatus',
        method: 'get',
        params: data
    })
}
//删除公章申请
export function apply_switch (data) {
    return request({
        url: 'apply/seal/apply_switch',
        method: 'post',
        data
    })
}
//废弃文件清除
export function clean_upfiles (data) {
    return request({
        url: '/user/admin/clean_upfiles',
        method: 'post',
        data
    });
}
//管理员模板开关
export function set_template_switch (data) {
    return request({
        url: 'temp/set_template_switch',
        method: 'post',
        data
    });
}
//批签最新二维码
export function add_new_code (data) {
    return request({
        url: 'contract/add_new_code',
        method: 'post',
        data
    });
}


